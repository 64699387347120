import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import localStorageCall from "../Methods/localstorage.hook";
import { _getPrice, _productPrice, _pointsPrice, storeAssigned } from "../Methods/normalMethods";
import { getAllProductApi } from "../Redux/Reducer/AllProductSlice";

const withProductHOC = (Component) => {

    const WithProductHOComponent = (props) => {

        const dispatch = useDispatch();
        const { addToCartProducts, setAddToCartProduct, section, setOpenMiniCart, params, productSection } = props;
        const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
        const COUNTRYID = { us: 1005, ca: 1011, au: 1017, nz: 1023, mx: 1029, ph: 1035, ng: 1041, cd: 1047, cm: 1053, ke: 1059, mu: 1065, gb: 1071 };

        const CATEGORIES = [
            { name: "All Products", id: COUNTRYID[storeAssigned()] },
            { name: "GSH", id: 1079 },
            { name: "Energy", id: 1080 },
            { name: "Combos", id: 1081 },
            // { name: "Samples", id: 1082 },
            { name: "Skin Care", id: 1083 },
            { name: "Mission Promo Packs", id: 1084 }
        ];
        const [selectedCategory, setSelectedCategory] = useState(COUNTRYID[storeAssigned()])


        const memoRizedSelector = createSelector(
            state => state?.AllProductSlice,
            (productState) => ({
                loading: productState?.isLoading
            })
        )
        const { loading } = useSelector((state) => memoRizedSelector(state))

        const [allProductState, setAllProductState] = useState([]);
        const [category, setCategory] = useState("All Products");
        useEffect(() => {
            _getPorductAccordingSection(selectedCategory);
            if (section === 'preferred' || section === 'IBO' || productSection === 'autoshipEdit') {
                setSelectedCategory(1084);
                // _getPorductAccordingSection(1084);
            }
        }, [section, selectedCategory, productSection]);

        function _getPorductAccordingSection(selectedCategory) {
            dispatch(getAllProductApi(String(section + 'Product'), (data) => {
                const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
                const ProductData = _.compact(_.map(data, (row) => {
                    const SECTION_USE = (CUSTOMER_TYPE?.role && section === 'normal') ? CUSTOMER_TYPE?.role : section;
                    // let CONDITION = _.includes(['IBO', 'preferred'], SECTION_USE) ? _getPrice(row, 3) && _getPrice(row, 6) : (_getPrice(row, 1) && _getPrice(row, 5));

                    let CONDITION;
                    if (selectedCategory === 1084) {
                        CONDITION = _.includes(['IBO', 'preferred'], SECTION_USE) ? _getPrice(row, 3) || _getPrice(row, 6) : (_getPrice(row, 1) && _getPrice(row, 5));

                    } else {
                        CONDITION = _.includes(['IBO', 'preferred'], SECTION_USE) ? _getPrice(row, 3) || _getPrice(row, 6) : (_getPrice(row, 1) && _getPrice(row, 5));

                    }

                    if (CONDITION) {
                        return _ProductStructure(row);
                    }
                }));
                const otherProducts = _.filter(ProductData, ({ title }) => !_.includes(title, ['COMBO', 'GSH', 'Energy', 'Provizion Sample']))

                const WithoutGSHAndEnergyPRODUCTS = _.reject(ProductData, ({ title }) => _.includes(title, 'COMBO'));
                const WithGSHAndEnergyPRODUCTS = _.filter(ProductData, ({ title }) => _.includes(title, 'COMBO'));
                const GSH_PRODUCTS = _.filter(WithoutGSHAndEnergyPRODUCTS, ({ title }) => _.includes(title, 'GSH'));
                const Energy_PRODUCTS = _.filter(WithoutGSHAndEnergyPRODUCTS, ({ title }) => _.includes(title, 'Energy'));
                const Provizion_Sample_PRODUCTS = _.filter(WithoutGSHAndEnergyPRODUCTS, ({ title }) => _.includes(title, 'Provizion Sample'));
                const sorted_GSH_PRODUCTS = _.orderBy(GSH_PRODUCTS, [String(section + 'oneTime')]);
                const sorted_Energy_PRODUCTS = _.orderBy(Energy_PRODUCTS, [String(section + 'oneTime')]);
                const sorted_GSH_Energy_PRODUCTS = _.orderBy(WithGSHAndEnergyPRODUCTS, [String(section + 'oneTime')]);
                const sorted_Sample_PRODUCTS = _.orderBy(Provizion_Sample_PRODUCTS, [String(section + 'oneTime')]);
                const Sorted_Products = _.uniqBy([...sorted_GSH_PRODUCTS, ...sorted_Energy_PRODUCTS, ...sorted_GSH_Energy_PRODUCTS, ...sorted_Sample_PRODUCTS, ...otherProducts], 'id');

                // console.log(Sorted_Products, 'Sorted_Products')
                if ((section === 'preferred' || section === 'IBO' || productSection === 'autoshipEdit')) {
                    setAllProductState((prv) => ([...prv, ...Sorted_Products]));
                }
                else {
                    setAllProductState(Sorted_Products);
                }
            }, selectedCategory));
        }

        function _addToCartFunction(id, frequency = "oneTime") {

            const PRODUCTS = addToCartProducts[section + 'Products'];
            const FIND_PRODUCT = _.find(allProductState, { id });
            const FIND_ALREADY_PRODUCT = _.find(PRODUCTS, { id });

            let ADD_PRODUCTS = [];
            if (FIND_ALREADY_PRODUCT) {
                ADD_PRODUCTS = [..._.reject(PRODUCTS, FIND_ALREADY_PRODUCT), { ...FIND_ALREADY_PRODUCT, frequency }];
            } else {
                ADD_PRODUCTS = [...PRODUCTS, { ...FIND_PRODUCT, frequency }]
            }

            localStorageCall().setItem(String(section + 'Products'), JSON.stringify(ADD_PRODUCTS));
            setAddToCartProduct((prv) => ({ ...prv, [section + 'Products']: ADD_PRODUCTS }));
            setOpenMiniCart(true);
        }

        //checkout products
        function _checkoutQuantityIncDec(id, section) {
            _minCartAndViewCartQuantityIncDec(id, section, () => {
                alert('checkout');
            })
        }

        function _frequancyChange() {

        }

        function _ProductStructure(row) {
            const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
            let NORMAL_PRICEING = { oneTime: _getPrice(row, 1), autoship: _getPrice(row, 5) }
            if (_.includes(['preferred', 'IBO'], CUSTOMER_TYPE?.role)) {
                NORMAL_PRICEING = { oneTime: _getPrice(row, 3), autoship: _getPrice(row, 6) };
            }
            return ({
                id: row?.itemId,
                itemId: row?.itemId,
                image: row?.largeImage,
                title: row?.title,
                normal_oneTime: NORMAL_PRICEING?.oneTime || 0,
                normal_autoship: NORMAL_PRICEING?.autoship || 0,
                retail_oneTime: _getPrice(row, 1) || 0,
                retail_autoship: _getPrice(row, 5) || 0,
                preferred_oneTime: _getPrice(row, 3) || 0,
                preferred_autoship: _getPrice(row, 6) || 0,
                IBO_oneTime: _getPrice(row, 3) || 0,
                IBO_autoship: _getPrice(row, 6) || 0,
                quantity: 1,
                itemCode: row?.fullItemCode,
                isEligibleForAutoOrder: row?.isEligibleForAutoOrder,
                stock: "",
                points: _pointsPrice(row?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder),
            })
        }

        return <Component {...props} {...{
            allProductState, setAllProductState, loading, CATEGORIES, selectedCategory, setSelectedCategory,
            _addToCartFunction, _ProductStructure, _frequancyChange, _checkoutQuantityIncDec,
            _getPorductAccordingSection, setCategory, category
        }} />
    }

    return WithProductHOComponent;
}

export default withProductHOC;